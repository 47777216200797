import React from "react"
import { Link } from "react-router-dom"
import { refreshAccessToken, gotoMobilePage, updateGameToken } from "../utils/webview"

const data = [
    {
        name: "Lucky wheel",
        url: "lucky-wheel",
    },
    {
        name: "Lucky wheel beyond",
        url: "lucky-wheel-beyond",
    },
    {
        name: "Lucky wheel ParisDot",
        url: "lucky-wheel-paris-dot",
    },
    {
        name: "Lucky wheel Polkadot",
        url: "lucky-wheel-polkadot-decoded-2023",
    },
    {
        name: "Weekly raffle",
        url: "/weekly-raffle",
    },
    {
        name: "Astra Degens",
        url: "/moonfit-astar-raffle",
    },
    {
        name: 'Apes Glmr',
        url: '/moonfit-glmr-apes-raffle'
    },
    {
        name: 'Welcome raffle',
        url: '/welcome-raffle'
    },
    {
        name: "Mones Glmr",
        url: "/moonfit-mones-raffle",
    },
    {
        name: 'Ajuna',
        url: '/moonfit-ajuna-raffle'
    },
    {
        name: 'Summer Fitsnap 1',
        url: '/advent_summer_fitsnap_challenge_raffle_1'
    },
    {
        name: 'Summer Fitsnap 2',
        url: '/advent_summer_fitsnap_challenge_raffle_2'
    },
    {
        name: 'Summer Fitsnap 3',
        url: '/advent_summer_fitsnap_challenge_raffle_3'
    },
    {
        name: 'Summer Fitsnap 4',
        url: '/advent_summer_fitsnap_challenge_raffle_4'
    },
    {
        name: 'Algem',
        url: '/moonfit-x-algem-challenge'
    },
    {
        name: 'Hashkey Did',
        url: '/moonfit-x-hashkey-did-challenge'
    },
    {
        name: 'Starfish Finance',
        url: '/moonfit-x-starfish-finance-challenge'
    },
    {
        name: 'AstridDAO',
        url: '/moonfit-x-astriddao-challenge'
    },
    {
        name: 'Yuliverse',
        url: '/moonfit-x-yuliverse-challenge'
    },
    {
        name: 'Welcome',
        url: '/welcome'
    },
    {
        name: 'Lunar Gaming Festival Thanksgiving Challenge',
        url: '/lunar-gaming-festival-thanksgiving-challenge'
    },
    {
        name: 'Christmas Challenge',
        url: '/christmas-challenge'
    },
    {
        name: 'Halloween Challenge',
        url: '/halloween-challenge'
    },
    {
        name: 'Valentine Challenge',
        url: '/valentine-challenge-2024'
    }
]

const HomePage = () => {

    const _refreshAccessToken = () => {
        refreshAccessToken()
    }

    return (
        <div className="home-page">
            <div className="ant-list ant-list-bordered ">
                <div className="ant-list-header">
                    <div>Home page</div>
                </div>
                <div className="ant-spin-container">
                    <ul className="ant-list-items">
                        {data.map((item) => (
                            <li className="ant-list-item">
                                <Link to={item.url} className="color-white">
                                    Go to {item.name}
                                </Link>
                            </li>
                        ))}
                        <li className="ant-list-item" onClick={_refreshAccessToken}>
                            Test refresh accessToken
                        </li>
                        <li className="ant-list-item" onClick={() => gotoMobilePage("daily_bonus")}>
                            Goto page daily_bonus
                        </li>
                        <li className="ant-list-item" onClick={() => gotoMobilePage("lucky_wheel")}>
                            Goto page lucky_wheel
                        </li>
                        <li
                            className="ant-list-item"
                            onClick={() => updateGameToken(Math.floor(Math.random() * 10000 + 1) / 100)}
                        >
                            Test updateGameToken
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default HomePage
